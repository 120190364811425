import React from 'react'
import style from './locale.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const Locale = ({ country, icon, link }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className={style.locale}>
      <div className={style.locale__content}>
        <h3>{country}</h3>{' '}
        <GatsbyImage
          className={style.locale__icon}
          image={icon.gatsbyImageData}
          loading={'eager'}
          alt={icon.alt}
          objectFit={'contain'}
        />
      </div>
    </a>
  )
}

export default Locale
