import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@layouts/layout'
import { Footer, Block, Locale } from '@components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import style from './index.mod.scss'
import LogoSvg from '@svgs/logo.svg'

const IndexPage = ({ data, pageContext }) => {
  const {
    seoMetaTags,
    introText,
    background,
    countries
  } = data.datoCmsDedikateLanding

  return (
    <Layout pageType="index">
      <HelmetDatoCms defer={false}>
        <title>{'Welcome to DediKate'}</title>
        <meta
          property="og:title"
          content={'Welcome to DediKate'}
          key="title"
        />
        <meta
          property="description"
          content={'The health and fitness platform for busy women!'}
          key="description"
        />
        <meta
          property="og:description"
          content={'The health and fitness platform for busy women!'}
          key="description"
        />

      </HelmetDatoCms>
      <div className={style.index}>
        <GatsbyImage
          className={style.index__background}
          image={background.gatsbyImageData}
          loading={'eager'}
          alt={background.alt}
        />
        <div gutters className={style.index__content_wrap}>
          <Block gutters>
            <div className={style.index__content}>
              <div>
                <div className={style.index__logo}>
                  <LogoSvg />
                </div>
                <h3>{introText}</h3>
                {countries.length > 0 &&
                  countries.map(item => (
                    <Locale
                      country={item.country}
                      icon={item.icon}
                      link={item.link}
                    />
                  ))}
              </div>
            </div>
          </Block>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsDedikateLanding {
      id
      background {
        gatsbyImageData(layout: FULL_WIDTH)
        alt
      }
      introText
      countries {
        country
        link
        icon {
          gatsbyImageData(imgixParams: { h: "35", fit: "crop" })
          alt
        }
      }
    }
  }
`
