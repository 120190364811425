import React, { useRef } from 'react'
import style from './footer.mod.scss'
import { Button } from '@components'

const Footer = () => {
  const classes = [style.footer]
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className={classes.join(' ')}>
      <div className={style.footer__container}>
        <div className={style.footer__inner}>
        <div className={style.footer__info}>
            <p>{`© Kate Ivey Fitness`}</p>
            <p>
              {'Designed and Developed by'}{' '}
              <Button
                unstyled
                to={'https://www.harveycameron.nz/marketing-solutions/website-design-and-development'}
                children={'Harvey Cameron'}
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
